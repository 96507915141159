import { render, staticRenderFns } from "./InputDialog.vue?vue&type=template&id=4ea871cd&scoped=true&"
import script from "./InputDialog.vue?vue&type=script&lang=js&"
export * from "./InputDialog.vue?vue&type=script&lang=js&"
import style0 from "./InputDialog.vue?vue&type=style&index=0&id=4ea871cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea871cd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import VBtn from '@/components/HOC/VBtn'
import { VForm } from 'vuetify/lib/components/VForm';
import VTextarea from '@/components/HOC/VTextarea'
installComponents(component, {VBtn,VForm,VTextarea})
