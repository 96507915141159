<template>
  <PhDialog
    v-model="shown"
    @close="handleDialogClose"
    hideFooter
    contentTextAlign="left"
    :fullscreen="true"
  >
    <div slot="content" class="input-dialog">
      <div class="input-dialog__title pa-4 pt-md-4 px-md-4 pb-md-8">
        <div v-if="title || $slots.title" class="input-dialog__title-content">
          <slot name="title">
            <h2 class="input-dialog__heading">{{ title }}</h2>
          </slot>
        </div>
      </div>
      <div class="input-dialog__content">
        <slot name="content">
          <v-form ref="form">
            <v-textarea
              v-model="reason"
              :label="placeholder"
              :rules="reasonRequired ? ['required'] : []"
              outlined
            />
          </v-form>
        </slot>
      </div>
      <div class="input-dialog__button-wrapper">
        <v-btn @click="handleActionClick" :loading="loading" class="input-dialog__button">
          {{ buttonText }}
        </v-btn>
      </div>
    </div>
  </PhDialog>
</template>

<script>
import { dialogWrapper, validateForm } from '@/mixins';

export default {
  name: 'InputDialog',
  mixins: [dialogWrapper, validateForm],
  data: () => ({
    reason: '',
  }),
  props: {
    title: {
      type: String,
      required: false,
    },
    reasonRequired: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: () => `Please specify a reason`,
    },
    buttonText: {
      type: String,
      default: () => `DECLINE`,
    },
    loading: Boolean,
  },
  watch: {
    value(newVal) {
      if (!newVal && this.reasonRequired) {
        this.reset();
      }
    },
  },
  methods: {
    handleActionClick() {
      if (this.reasonRequired) {
        const valid = this.validate();
        if (!valid) {
          return;
        }
      }
      this.$emit('dialogSubmitted', this.reason);
      this.handleDialogClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.input-dialog__title {
  width: 100%;
  text-align: center;
  .input-dialog__heading {
    margin-bottom: 0;
    font-weight: 600;
    color: $text-dark;
  }
}
.input-dialog__button-wrapper {
  display: flex;
  justify-content: center;
}
.input-dialog__content {
  max-width: 358px;
  width: 100%;
  margin: 0 auto;
}
.input-dialog {
  min-height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
